import './App.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import MusicListing from './components/MusicListing';
import EmbedPlayer from './components/EmbedPlayer';
import Socials from './components/Socials';

function App() {
  return (
    <>
  {/* <Navbar /> */}
  <Hero />
  <EmbedPlayer/>
  <MusicListing />
  <Socials />
  <Footer />


    </>
  );
}

export default App;
