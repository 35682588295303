import React from 'react'

const Footer = () => {
  return (
    <div>

      <a href="https://jonesy.tech" className='grid justify-center py-4 bg-gp-eerie-black text-white text-lg'>Created by Jonesytech</a>
      </div>
  )
}

export default Footer