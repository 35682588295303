import React from "react";
import spotifyicon from "./assets/images/Spotify_Logo_RGB_Green.png";
import bandcampicon from "./assets/images/bandcamp-logotype-color-512.png";
import applemusicicon from "./assets/svg/Apple-Music.svg";

const MusicListing = () => {
  return (
    <>
      <div className="grid grid-cols-1 py-6 md-plus:px-96" id="mainListing">
        <a
          href="https://gonpulvo.bandcamp.com"
          id="bandcamp"
        >
          <img
            className="px-8 md:px-16 lg:px-64 flex flex-auto place-items-center"
            src={bandcampicon}
            alt="Check out gon pulvo's releases on Bandcamp"
          />
        </a>
      </div>
      <div
        id="sublisting"
        className="grid grid-cols-2 place-items-center pb-12 md:"
      >
        <a href="https://open.spotify.com/artist/0wpTBw95kzELqBdKBls40L">
       <img
          src={spotifyicon}
          alt="Stream gon pulvo on Spotify"
          className="justify-center w-80 px-8"
        /></a>
         <a href="https://music.apple.com/artist/gon-pulvo/1333207681">
        <img
          src={applemusicicon}
          alt="Listen to gon pulvo on Apple Music"
          className="justify-center w-80 px-8"
        /></a>
      </div>
    </>
  );
};

export default MusicListing;
