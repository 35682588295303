import React from "react";
import twitch from "./assets/svg/TwitchGlitchPurple.svg";
import twitter from "./assets/images/twitterlogo.png";
import instagram from "./assets/images/Instagram_Glyph_Gradient.png";
import youtube from "./assets/images/yt_icon_rgb.png";

const Socials = () => {
  return (
    <div
      id="socials-section"
      className="py-16 flex flex-wrap flex-shrink  md:px-auto bg-gp-azx11-blue" 
    >
      <div className="my-8 md:w-full w-2/4  cursor-pointer basis-2/4 lg:basis-1/4 justify grid justify-center">
        <a href="https://twitch.tv/poruvo"><img
          src={twitch}
          className="w-20 h-20 lg:w-32 lg:h-32"
          alt="See what gon pulvo's up to on Twitch!"
          id="twitch"
        />
        </a>
      </div>
      <div className="my-8 md:w-full w-2/4  cursor-pointer basis-2/4 lg:basis-1/4 grid justify-center ">
        <a href="https://www.youtube.com/channel/UCS_YBs-5B2oVnlrQNsE9d2Q">
        <img
          src={youtube}
          className="w-20 h-20 lg:w-32 lg:h-32"
          alt="Watch gon pulvo on Youtube"
        /></a>
      </div>

      <div className="my-8 md:w-full w-2/4  cursor-pointer basis-2/4 lg:basis-1/4  grid justify-center">
        <a href="https://instagram.com/poruvo">
        <img
          src={instagram}
          className="w-20 h-20 lg:w-32 lg:h-32"
          alt="gon pulvo's instagram"
        /></a>
      </div>
      <div className=" my-8 md:w-full w-2/4  cursor-pointer basis-2/4 lg:basis-1/4 grid justify-center">
        <a href="https://twitter.com/gonpulvo"><img
          src={twitter}
          className="w-20 h-20 lg:w-32 lg:h-32"
        
          alt="gon pulvo's twitter feed"
        /></a>
      </div>
    </div>
  );
};

export default Socials;
