import React from 'react'
import ReactPlayer from 'react-player'

const EmbedPlayer = () => {
  
  const songUrls = {
    "lhfp":"https://soundcloud.com/poruvo/lovehatredfearperception?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "bb":"https://soundcloud.com/poruvo/smashbros?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "kc-hb":"https://soundcloud.com/poruvo/gpheartbeat?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "hzdz":"https://soundcloud.com/poruvo/haze-daze?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "antihero":"https://soundcloud.com/poruvo/antihero?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "sac":"https://soundcloud.com/poruvo/sacrifices?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "toa_neg":"https://soundcloud.com/poruvo/adversity-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "jyuichi":"https://soundcloud.com/poruvo/sanningen?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "blkdog":"https://soundcloud.com/poruvo/blkdog?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "tyrant":"https://soundcloud.com/poruvo/tyrant?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "prelude": "https://soundcloud.com/poruvo/psanningen?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "spicy-salt": "https://soundcloud.com/poruvo/spicy-salt?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    "ice_amb":"https://soundcloud.com/poruvo/ice?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"

}
  const songRandomizer = (urls) =>{
   // Create array of object keys, ["311", "310", ...]
const keys = Object.keys(urls)

// Generate random index based on number of keys
const randIndex = Math.floor(Math.random() * keys.length)

// Select a key from the array of keys using the random index
const randKey = keys[randIndex]

// Use the key to get the corresponding name from the "names" object
const name = urls[randKey]
// console.log(name)
return name
  }
  return (
    <div className="py-8 flex justify-center bg-gp-maxblue">
      <ReactPlayer url={songRandomizer(songUrls)}
      className=""
      width='90%'
      config={{
        soundcloud: {
          options:{
            auto_play:'true',
            color:'#1c1c1c'
          }

        }
      }}/>
    </div>
  )
}

export default EmbedPlayer