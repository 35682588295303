import React from "react";
import logo from "./assets/images/gp_betterltbluestroke.png";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
const Hero = () => {
  return (
    <div className="grid  bg-gpblack justify-items-center lg:grid-cols-2">
      <img src={logo} alt="gon pulvo logo" className="flex px-8 pt-16 lg:p-12 place-items-center" />

      <div className="grid place-items-center grid-rows-1 text-white py-16" >
        
          <p className="text-lg px-16 py-0">
          <h1 className="text-3xl">Experimental Electronic Music Producer</h1> <br />
            Welcome to my muse. This project exists because I love making music with waveforms and working under limitations to create something new. <br/> <br />
          
          I use <a href="https://littlegptracker.com/" className="hover:text-blue-500">LittleGPTracker(PiggyTracker)</a> to write songs and I master them in a DAW. <br /> <br /> Why this app? Aren't DAWs faster? It's fun, it's fast to get ideas down, and easy to make some music in a few minutes. 
          <br /><br />
          Listen to some songs below and check me out on other social media platforms. You can also find me on most major music streaming platforms! 
          <br />
          <br />
          <AnchorLink href="#bandcamp"><span className="bg-gp-cyan text-white rounded p-4 mb-2">Streaming Platforms</span> <br /> <br /> <br /></AnchorLink>
          <span className="text-xs bg-blue-100 text-black rounded p-4 mt-8 hover:bg-gp-maxblue"> <a href="https://sovarozum.com">(My other project, Sovarozum)</a></span>
          </p>
          
        </div>  
      </div>
  );
};

export default Hero;
